import React, { useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { Spinner, UncontrolledTooltip  } from 'reactstrap';

//import images
import MSR_ETF from "../../assets/images/products/MSR_ETF.svg";
import MSR_INCOME from "../../assets/images/products/MSR_INCOME.svg";
import MSR_INV from "../../assets/images/products/MSR_INV.svg";
import MSR_CRYPTO from "../../assets/images/products/MSR_CRYPTO.svg";
import MSR_CRYVUES from "../../assets/images/products/MSR_CRYVUES.svg";
import PATS from "../../assets/images/products/PATS.svg";
import MSR_RSC_PORT from "../../assets/images/products/MSR_RSC_PORT.svg";
import SMALL_CAPS from "../../assets/images/products/SMALL_CAPS.svg";
import MSR_SF from "../../assets/images/products/MSR_SF.svg";
import MSR_RESEARCH from "../../assets/images/products/MSR_RESEARCH.svg";
import IPATS from "../../assets/images/products/IPATS.svg";
import HALO_INV_US from "../../assets/images/products/HALO_INV_US.svg";
import HALO_INV_ASIA from "../../assets/images/products/HALO_INV_ASIA.svg";
import HALO_INV_EMEA from "../../assets/images/products/HALO_INV_EMEA.svg";
import { Link } from 'react-router-dom';
import { api } from "../../config";

const iconMapping = {
    MSR_ETF,
    MSR_INCOME,
    MSR_INV,
    MSR_CRYPTO,
    MSR_CRYVUES,
    PATS,
    MSR_RSC_PORT,
    SMALL_CAPS,
    MSR_SF,
    MSR_RESEARCH,
    IPATS,
    HALO_INV_US,
    HALO_INV_ASIA,
    HALO_INV_EMEA
};

const fetchMain = async ({ queryKey }) => {
    const clientID = sessionStorage.getItem('clientID');
    const sessionloginKey = sessionStorage.getItem('loginKey');
  
    const response = await axios.get(
      `${api.API_URL}/api_userProducts.cfm?action=dashboard&clientID=${clientID}`, 
      {
        headers: {
          'X-User-GUID': sessionloginKey,
          'Authorization': sessionloginKey
        }
      }
    );
    
    return response.data;
  };
  

const WebAppsDropdown = () => {
    const [isWebAppDropdown, setIsWebAppDropdown] = useState(false);
    const toggleWebAppDropdown = () => {
        setIsWebAppDropdown(!isWebAppDropdown);
    };

    const { data: productSuite, isLoading, isError } = useQuery({
        queryKey: ['productQuery'],
        queryFn: fetchMain,
      });

    if (isLoading) {
        return <Spinner>Loading...</Spinner>;
      }
    
      if (isError) {
        return <div>Error loading data...</div>;
      }

    return (
        <React.Fragment>
    <Dropdown isOpen={isWebAppDropdown} toggle={toggleWebAppDropdown} className="topbar-head-dropdown ms-1 header-item">
        <DropdownToggle tag="button" type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
            <i className='bx bx-category-alt fs-22'></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end">
            <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                <Row className="align-items-center">
                    <Col>
                        <h6 className="m-0 fw-semibold fs-15"> Product Suite </h6>
                    </Col>
                    <div className="col-auto">
                        <Link to="/" className="btn btn-sm btn-soft-info"> Dashboard
                            <i className="ri-arrow-right-s-line align-middle"></i></Link>
                    </div>
                </Row>
            </div>

            <div className="p-2">
                {(productSuite || []).reduce((acc, productQuery, index) => {
                    // Check if productGroup has changed
                    if (index === 0 || productQuery.productGroup !== acc.prevGroup) {
                        // If we already have avatars in the currentAvatarGroup, push it as a row before starting the new group
                        if (acc.currentAvatarGroup.length > 0) {
                            acc.rows.push(
                                <div key={`row-${acc.prevGroup}`} className="avatar-group mb-2">
                                    {acc.currentAvatarGroup}
                                </div>
                            );
                            acc.currentAvatarGroup = []; // Reset for the new group
                        }

                        // Insert a new header for the productGroup
                        acc.rows.push(
                            <div key={`group-${productQuery.productGroup}`} className="row g-0">
                                <h6 className="mt-2 mb-1" style={{ textTransform: 'uppercase' }}>{productQuery.productGroup}</h6>
                            </div>
                        );

                        acc.prevGroup = productQuery.productGroup; // Update previous group
                    }

                    // Generate unique ID for the tooltip and avatar
                    const uniqueId = `product-${productQuery.productID}-${index}`;

                    // Add the current product as an avatar-like item in the currentAvatarGroup
                    acc.currentAvatarGroup.push(
                        <React.Fragment key={productQuery.productID}>
                            <Link to={`../${productQuery.reportLink}`} className="avatar-group-item" id={uniqueId}>
                                <img src={iconMapping[productQuery.groupcode] || MSR_ETF} alt={productQuery.productAlias} className="img-thumbnail rounded-circle avatar-sm" />
                            </Link>
                            {/* Add Tooltip for each avatar */}
                            <UncontrolledTooltip placement="top" target={uniqueId}>
                                {productQuery.productAlias}
                            </UncontrolledTooltip>
                        </React.Fragment>
                    );

                    // If it's the last product, push the remaining avatars into a row
                    if (index === (productSuite.length - 1)) {
                        acc.rows.push(
                            <div key={`row-last`} className="avatar-group mb-2">
                                {acc.currentAvatarGroup}
                            </div>
                        );
                    }

                    return acc;
                }, { prevGroup: null, currentAvatarGroup: [], rows: [] }).rows}
            </div>
        </DropdownMenu>
    </Dropdown>
</React.Fragment>
    );
};

export default WebAppsDropdown;



