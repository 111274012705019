import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';

import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import * as Yup from "yup";
import { useFormik } from "formik";
import { api } from "../../config";

import logoLight from "../../assets/images/logo-light.svg";
import aaigLogo from "../../assets/images/companies/AAIG.svg";
import HALOLogo from "../../assets/images/companies/HaloTechnologies.svg";
import apsecLogo from "../../assets/images/companies/Apsec.svg";
import asrwLogo from "../../assets/images/companies/ASRWealthAdvisers.svg";
import CPGLogo from "../../assets/images/companies/continental-property-group.svg";
import leapLogo from "../../assets/images/companies/LeapDigitalInvestments.svg";

const Login = () => {
    sessionStorage.removeItem('authUser');
    sessionStorage.removeItem('expirationTime');
    sessionStorage.removeItem('clientID');
    sessionStorage.removeItem('permissions');
    sessionStorage.removeItem('loginKey');

    const location = useLocation();
    const { sessionExpired } = location.state || {};
    const navigate = useNavigate();

    const [passwordShow, setPasswordShow] = useState(false);
    const [showAlert, setShowAlert] = useState(sessionExpired || false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("info");
    const [isSessionExpired, setIsSessionExpired] = useState(sessionExpired || false);
    const [rememberMe, setRememberMe] = useState(false);


    useEffect(() => {
        if (isSessionExpired) {
            setAlertMessage("Your session has expired. Please log in again.");
            setAlertType("info");
            setShowAlert(true);
        }
    }, [isSessionExpired]);

    useEffect(() => {
        const savedEmail = localStorage.getItem('rememberedEmail');
        if (savedEmail) {
            validation.setFieldValue('email', savedEmail);
            setRememberMe(true);  // Set checkbox as checked if email is remembered
        }
    }, []);

    useEffect(() => {
        if (showAlert && !isSessionExpired) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [showAlert, isSessionExpired]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email format").required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: async (values) => {
            try {
                const response = await fetch(api.API_URL + '/api_login.cfm', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(values),
                });
        
                const data = await response.json();
        
                if (!response.ok) {
                    handleError([response.status]);
                    return;
                }
                
                // Assuming errors from API are returned as an array with error code at data[0]
                // To avoid false positives, ensure data[0] is treated as an error only when appropriate.
                if (Array.isArray(data) && data.length === 1 && typeof data[0] === 'number' && data[0] >= 400) {
                    handleError(data);
                    return;
                }
        
                if (typeof data === 'object' && data !== null) {
                    // Save email if 'Remember Me' is checked, otherwise remove it
                    if (rememberMe) {
                        localStorage.setItem('rememberedEmail', values.email);
                    } else {
                        localStorage.removeItem('rememberedEmail');
                    }
        
                    // Your existing login logic here
                    const clientID = data[0];
                    const firstName = data[1];
                    const lastName = data[2];
                    const permissions = data[3];
                    const loginKey = data[4];
        
                    const expirationTime = Date.now() + 30 * 60 * 1000; // 30min
                    const user = {
                        clientID,
                        firstName,
                        lastName,
                        permissions: permissions.split(','),
                        loginKey,
                    };
        
                    sessionStorage.setItem('authUser', JSON.stringify(user));
                    sessionStorage.setItem('expirationTime', expirationTime);
                    sessionStorage.setItem('clientID', clientID);
                    sessionStorage.setItem('permissions', JSON.stringify(user.permissions));
                    sessionStorage.setItem('loginKey', loginKey);
        
                    navigate('/dashboard');
                } else {
                    throw new Error("Unexpected response format");
                }
            } catch (error) {
                console.error(error.message);
                setAlertMessage(error.message);
                setAlertType('danger');
                setShowAlert(true);
            }
        }
        
    });

    const handleError = (errorData) => {
        let message;
        let type;

        switch (errorData[0]) {
            case 404:
                message = "Sorry! The page you are looking for could not be found.";
                type = "danger";
                break;
            case 500:
                message = "Sorry! Something went wrong. Please contact support.";
                type = "danger";
                break;
            case 401:
                message = "Invalid login details. Please try again.";
                type = "warning";
                break;
            default:
                message = "An unknown error occurred. Please try again.";
                type = "danger";
        }

        if (!isSessionExpired) {
            setAlertMessage(message);
            setAlertType(type);
            setShowAlert(true);
        }
    };

    document.title = "Login - Australian Stock Report";

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center mt-sm-5 mb-4 text-white-50">
                                <Link to="/" className="d-inline-block auth-logo">
                                    <img src={logoLight} alt="Logo" height="90" />
                                </Link>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Login to your Account</h5>
                                            {showAlert && (
                                                <div className={`alert alert-${alertType}`} role="alert">
                                                    {alertMessage}
                                                </div>
                                            )}
                                        </div>

                                        <div className="p-2 mt-4">
                                            <Form onSubmit={validation.handleSubmit}>
                                                <div className="mb-3">
                                                    <Label htmlFor="email">Email</Label>
                                                    <Input
                                                        name="email"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email}
                                                        invalid={validation.touched.email && validation.errors.email}
                                                    />
                                                    {validation.touched.email && validation.errors.email && (
                                                        <FormFeedback>{validation.errors.email}</FormFeedback>
                                                    )}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                    </div>
                                                    <Label htmlFor="password">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            type={passwordShow ? "text" : "password"}
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password}
                                                            invalid={validation.touched.password && validation.errors.password}
                                                        />
                                                        {validation.touched.password && validation.errors.password && (
                                                            <FormFeedback>{validation.errors.password}</FormFeedback>
                                                        )}
                                                        <button type="button" className="btn btn-link position-absolute end-0 top-0 text-muted" onClick={() => setPasswordShow(!passwordShow)}>
                                                            <i className="ri-eye-fill align-middle"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="auth-remember-check"
                                                        checked={rememberMe}
                                                        onChange={(e) => setRememberMe(e.target.checked)}
                                                    />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">
                                                        Remember me
                                                    </Label>
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="success" disabled={validation.isSubmitting} className="w-100" type="submit">
                                                        {validation.isSubmitting && <Spinner size="sm" className='me-2' />} Sign In
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12} className="text-center">
                                <p className="mb-0 text-muted">Australian Stock Report is part of the</p>
                                <img src={aaigLogo} alt="AAIG Logo" height="60" />
                                <p className="mb-0 text-muted">Family of Companies</p>
                                <img src={asrwLogo} alt="ASRW Logo" style={{ height: 40, margin: '15px' }} />
                                <img src={HALOLogo} alt="HALO Logo" style={{ height: 40, margin: '15px' }} />
                                <img src={leapLogo} alt="Leap Digital Logo" style={{ height: 40, margin: '15px' }} />
                                <img src={apsecLogo} alt="APSEC Logo" style={{ height: 40, margin: '15px' }} />
                                <img src={CPGLogo} alt="Continental Property Group Logo" style={{ height: 100, margin: '15px' }} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Login;
