import React, { memo,useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumbSVG from '../../../Components/Common/BreadCrumbSVG';
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../config";
import classnames from "classnames";
//import StockData from '../../../Components/Common/StockData';

import ASRTracker from '../ASRTracker';
import ASRClosed from '../ASRClosed';




//import images
//import { ReactComponent as MSR_INV } from "../../../assets/images/products/INVESTING.svg";
import investmentLogo from "../../../assets/images/products/MSR_INV.svg";


const Investing = () => {
  document.title="Australian Stock Report | Investing Report";

    const [activeTab, setActiveTab] = useState("1");
  
    const toggleTab = (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    };

  return (
    <React.Fragment>      
      <div className="page-content">
        <Container fluid>
        <BreadCrumbSVG  title="Investing Report" pageTitle="Reports"
                svgComponent={<img src={investmentLogo} alt="ASRW Logo" style={{ height: 30, width: 30, margin: '0px', display: 'inline-block' }} />}
            />
          <Row>
          <Col xl={2}>
            <Card>
             <CardBody className="p-0"> Latest Weekly </CardBody>
            </Card> 
            <Card>
             <CardBody className="p-0"> Investing Guidelines</CardBody>
            </Card> 
            </Col>
            <Col xl={2}>
            <Card>
             <CardBody className="p-0">  Sector breakdown  </CardBody>
            </Card>   
            </Col>
            <Col xl={2}>
            <Card>
             <CardBody className="p-0">    Return   </CardBody>
            </Card> 
            </Col>
            <Col xxl={6}>
             
            </Col>
            
          </Row>  

          <Row>
      <Col>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
            >
              Trade Ideas
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
            >
              Closed Trades
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
          <ASRTracker streamCode="INVESTMENT" uFolder="pats" />
          </TabPane>
          <TabPane tabId="2">
          <ASRClosed streamCode="INVESTMENT" uFolder="pats" />
          </TabPane>
        </TabContent>
      </Col>
    </Row>


      
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Investing;