import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button,Dropdown, DropdownMenu, DropdownToggle, Form, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import Swal from "sweetalert2";
//import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

//import Components
import SearchOption from '../Components/Common/SearchOption';
import WebAppsDropdown from '../Components/Common/WebAppsDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';

import { changeSidebarVisibility } from '../slices/thunks';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';

const Header = ({ headerClass }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [hasShownAlert, setHasShownAlert] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null); // Change the initial value to null



  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };


  useEffect(() => {
    const interval = setInterval(() => {
      const authUser = sessionStorage.getItem("authUser");
      const expirationTime = sessionStorage.getItem("expirationTime");
  
      const parsedExpirationTime = parseInt(expirationTime, 10);
  
      if (authUser && !isNaN(parsedExpirationTime)) {
        const currentTime = Date.now();
        const remainingTimeInSeconds = Math.max(
          0,
          Math.ceil((parsedExpirationTime - currentTime) / 1000)
        );
  
        if (remainingTimeInSeconds === 0) {
          // ❌ Close any open SweetAlert before redirecting
          Swal.close();
  
          sessionStorage.removeItem("authUser");
          sessionStorage.removeItem("expirationTime");
          setIsSessionExpired(true);
          navigate("/login", { state: { sessionExpired: true } });
        } else {
          setRemainingTime(remainingTimeInSeconds);
          setIsSessionExpired(false);
  
          // Show SweetAlert when 2 minutes (120 seconds) remain
          if (remainingTimeInSeconds === 120 && !hasShownAlert) {
            setHasShownAlert(true);
  
            let countdown = 120; // Start countdown from 2 minutes
  
            const alertBox = Swal.fire({
              title: "Session Expiring Soon!",
              html: `<strong>Your session will expire in <span id="countdown">${Math.floor(countdown / 60)}:${String(countdown % 60).padStart(2, "0")}</span> minutes.</strong>`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Extend Session",
              cancelButtonText: "Logout",
              didOpen: () => {
                const countdownElement = document.getElementById("countdown");
  
                const countdownInterval = setInterval(() => {
                  countdown--;
                  if (countdownElement) {
                    countdownElement.textContent = `${Math.floor(countdown / 60)}:${String(countdown % 60).padStart(2, "0")}`;
                  }
  
                  if (countdown <= 0) {
                    clearInterval(countdownInterval);
                  }
                }, 1000);
  
                Swal.getPopup().setAttribute("data-countdown-interval", countdownInterval);
              },
              willClose: () => {
                // Clear countdown interval when the alert closes
                const intervalId = Swal.getPopup().getAttribute("data-countdown-interval");
                if (intervalId) {
                  clearInterval(intervalId);
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                // Extend session by 30 minutes
                const newExpirationTime = Date.now() + 30 * 60 * 1000;
                sessionStorage.setItem("expirationTime", newExpirationTime.toString());
                setHasShownAlert(false);
                Swal.fire("Session Extended", "Your session has been extended by 30 minutes.", "success");
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                // User clicked Logout -> clear session and redirect
                Swal.close();
                sessionStorage.removeItem("authUser");
                sessionStorage.removeItem("expirationTime");
                navigate("/login", { state: { sessionExpired: true } });
              }
            });
          }
        }
      } else {
        setRemainingTime(null);
        setIsSessionExpired(false);
      }
    }, 1000);
  
    return () => clearInterval(interval);
  }, [navigate, hasShownAlert]);

  

  

    const [search, setSearch] = useState(false);
    const toogleSearch = () => {
        setSearch(!search);
    };

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;
        dispatch(changeSidebarVisibility("show"));

        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle('open');

        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }

        //For collapse vertical and semibox menu
        if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }


        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };

    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            
                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>


                            <SearchOption />
                        </div>
                        <div className="d-flex align-items-center sesionTimeShow">
                        {isSessionExpired ? (
        <p>Your session has expired.</p>
      ) : remainingTime !== null ? ( // Check if remainingTime is not null before displaying it
        <p>Session will expire in: {formatTime(remainingTime)}</p>
      ) : null}    
                        </div>
                        
                        <div className="d-flex align-items-center">
                        
                            {/* LanguageDropdown
                            <LanguageDropdown /> */}

                            {/* WebAppsDropdown */}
                            <WebAppsDropdown />

                            {/* MyCartDropdwon 
                            <MyCartDropdown />*/}
                     

                            {/* NotificationDropdown 
                            <NotificationDropdown />*/}

                            {/* ProfileDropdown */}
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>
           
        </React.Fragment>
    );
};

export default Header;