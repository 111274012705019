import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import SparklineChart from './SparklineChart';
import { api } from "../../config";

const fetchMain = async ({ queryKey }) => {
    const [, country, symbol] = queryKey;
  
    const clientID = sessionStorage.getItem('clientID');
    const sessionloginKey = sessionStorage.getItem('loginKey');
  
    try {
      const response = await axios.get(api.API_URL + `/inc_chart_data.cfm?action=qDetails&c=${country}&s=${symbol}&clientID=${clientID}`, {
        headers: {
          'X-User-GUID': sessionloginKey,
          'Authorization': sessionloginKey
        }
      });
  
      // Check if the response has data
      if (response.data && response.data.length > 0) {
        return response.data[0]; // Return the first element in the data array
      } else {
        throw new Error('No data available');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized: Invalid login key');
        window.location.href = '/login'; // Redirect to login page
      } else {
        console.error('An error occurred:', error.message);
        throw new Error('Data Error'); // Throw generic error for UI handling
      }
    }
  };

const StockInfo = ({ country, symbol }) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ['mainData', country, symbol],
    queryFn: fetchMain,
  });

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading data: {error.message}</div>;
  if (!data) return <div>No data available</div>;

  const priceReturn = data.Price_Return !== undefined ? data.Price_Return.toFixed(2) : 'N/A';

  return (
    <Container fluid>
  <Row className="w-100 d-flex align-items-stretch">
    <Col xl={3} className="mb-3">
      <Card className="h-100">
        <CardBody>
          <SparklineChart country={country} symbol={symbol} />
          <p className="m-3">
            <strong>Market Cap:</strong> {data.market_cap}<br />
            <strong>Sector:</strong> {data.HALOSector}<br />
            <strong>Consensus Recommendation:</strong> {data.ConsensusRecommendation}<br />
            <strong>Consensus Price Target:</strong> {data.currencystring} {data.ConsensusPriceTarget}<br />
            <strong>Price Difference:</strong> 
            {data.Price_Diff !== undefined && (
              <span 
                className={`center-align ${data.Price_Diff < 0 ? 'text-danger' : data.Price_Diff > 0 ? 'text-success' : ''}`}
              >
                {data.Price_Diff}%
              </span>
            )}
<br />
            <strong>Deviation to Target Price:</strong> {data.DeviationToTargetPrice}<br />
            <strong>Next Report Date:</strong> {data.nextReportDate || 'TBA'}
          </p>
        </CardBody>
      </Card>
    </Col>



    <Col xl={9} className="mb-3">
      <Card className="h-100">
        <CardBody>
        <div className="d-flex align-items-center">
        <object
              data={`https://cdncontent.halotechnologies.com.au/images/LOGOS-SVG/${data.LogoFile}`}
              type="image/svg+xml"
              height="90"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            >
              <img
                src={`https://cdncontent.halotechnologies.com.au/images/LOGOS-MASTER/${data.LogoFile.replace('.svg', '.png')}`}
                alt={data.Symbol}
                style={{
                  height: '90px',
                }}
              />
            </object>
          
          <h5 className="ms-2 mb-0"> {data.SymbolName}
          <p className="fw-light mb-0 fs-6 text">{data.currencystring} {data.LastPrice} {priceReturn !== undefined && ( 
              <span className={`fw-medium ${priceReturn < 0 ? 'text-danger' : priceReturn > 0 ? 'text-success' : ''}`}>{priceReturn}%</span>
            )}<span className="text-muted ms-2 text-uppercase">({data.Symbol})</span></p></h5>
            </div>

          
          <p>{data.CompanyDescription}</p>
          <p>
            <strong>Website:</strong> <a href={`https://${data.Website}`} target="_blank" rel="noopener noreferrer">{data.Website}</a>
          </p>
          <p><strong>Exchange Code:</strong> {data.exchangecode}</p>
        </CardBody>
      </Card>
    </Col>
  </Row>
</Container>
  );
};

export default StockInfo;
