import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import * as d3 from 'd3';
import StockChart from './StockChart';
import { api } from "../../config";

const calculateEMA = (data, period) => {
  const k = 2 / (period + 1);
  let emaPrevious;
  return data.map((d, i) => {
    if (i < period - 1) return d;
    if (i === period - 1) {
      emaPrevious = data.slice(0, period).reduce((sum, prev) => sum + prev.Close, 0) / period;
    } else {
      emaPrevious = (d.Close - emaPrevious) * k + emaPrevious;
    }
    return { ...d, [`EMA${period}`]: emaPrevious };
  });
};

const calculateBollingerBands = (data, period = 20, stdDev = 2) => {
  return data.map((d, i, arr) => {
    if (i < period - 1) return null; // Skip if not enough data points

    const slice = arr.slice(i - period + 1, i + 1);
    const mean = d3.mean(slice, d => d.Close);
    const deviation = stdDev * d3.deviation(slice, d => d.Close);
    const upper = mean + deviation;
    const lower = mean - deviation;

    return {
      date: d.date,
      mean,
      upper,
      lower,
      index: i  // Add index to ensure proper mapping
    };
  }).filter(d => d); // Remove null values
};

const fetchMain = async ({ queryKey }) => {
  const [, c, s, isMobileProp] = queryKey; // Get `isMobileProp` from the queryKey

  const clientID = sessionStorage.getItem('clientID');
  const sessionloginKey = sessionStorage.getItem('loginKey');

  try {
    const response = await axios.get(api.API_URL + `/inc_chart_data.cfm?action=qMain&c=${c}&s=${s}&d=400&clientID=${clientID}`, {
      headers: {
        'X-User-GUID': sessionloginKey,  // Using sessionloginKey as userGUID
        'Authorization': sessionloginKey // Also using sessionloginKey for Authorization
      }
    });

    const apiData = response.data;

    let data = apiData.map(item => {
      const date = new Date(item.AsAtDate);
      const dateUnix = date.getTime();
      return {
        ...item,
        AsAtDate: date,
        date: dateUnix,
        High: +item.High,
        Low: +item.Low,
        Volume: +item.Volume,
        Open: +item.Open,
        Close: +item.Close,
        TP: +item.TP,
      };
    });

    // Determine isMobile based on passed prop or fallback to window.innerWidth check
    const isMobile = isMobileProp === '1' || window.innerWidth < 768;

    const earliestDateForEMA = new Date();
    earliestDateForEMA.setMonth(earliestDateForEMA.getMonth() - 24);
    earliestDateForEMA.setDate(earliestDateForEMA.getDate() - 120);

    data = data.filter(d => d.AsAtDate >= earliestDateForEMA);

    data = calculateEMA(data, 8);
    data = calculateEMA(data, 21);
    data = calculateEMA(data, 120);

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 4);  // Go back 3 months

    const MobileData = isMobile ? data.filter(d => d.AsAtDate >= threeMonthsAgo) : data;

    const dateLimit = new Date();
    dateLimit.setMonth(dateLimit.getMonth() - 12);

    const mobileLimit = new Date();
    mobileLimit.setDate(mobileLimit.getDate() - 21);

    const startDateForBollingerBands = new Date(dateLimit);
    startDateForBollingerBands.setDate(startDateForBollingerBands.getDate() - 30);

    const filteredData = data.filter(d => d.AsAtDate >= dateLimit);
    const dataForBollingerBands = data.filter(d => d.AsAtDate >= startDateForBollingerBands);

    const bollingerBands = calculateBollingerBands(dataForBollingerBands);

    return { filteredData, bollingerBands, MobileData };

  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized: Invalid login key');
      window.location.href = '/login'; // Redirect to login page
    } else {
      console.error('An error occurred:', error.message);
      throw new Error('Data Error');
    }
  }
};

const StockData = ({ c, s }) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ['mainData', c, s, '1'],  // Pass the isMobileProp as a query key
    queryFn: fetchMain,
  });

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading data: {error.message}</div>;
  if (!data || data.length === 0) return <div>No data available</div>;

  // Define the isMobile check before rendering StockChart
  const isMobile = data.MobileData ? window.innerWidth < 768 || data.MobileData.length < 100 : false;

  return (
    <div>
      <StockChart 
        data={isMobile ? data.MobileData : data.filteredData} 
        bollingerBands={data.bollingerBands} 
        isMobile={isMobile}  // Pass isMobile as a prop
      />
    </div>
  );
};

export default StockData;
