import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

const PDFModalViewer = ({ isOpen, toggleModal, file, modalTitle }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsLoaded(false);
    }
  }, [isOpen]);

  const isPDF = file && file.endsWith('.pdf');

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop="static" keyboard={false}>
      <ResizableBox
        width={800}
        height={600}
        minConstraints={[400, 300]}
        maxConstraints={[1200, 800]}
        resizeHandles={['se']} // Adds the resize handle at the bottom-right
        style={{ padding: '0', background: 'white', borderRadius: '8px' }}
      >
        <ModalHeader toggle={toggleModal}>{modalTitle || 'PDF Viewer'}</ModalHeader>
        <ModalBody style={{ height: 'calc(100% - 56px)', padding: '0' }}>
          {isPDF ? (
            <>
              {!isLoaded && <div style={{ textAlign: 'center', padding: '20px' }}>Loading PDF...</div>}
              <iframe
                title="PDF Viewer"
                src={file}
                onLoad={() => setIsLoaded(true)}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                }}
              />
            </>
          ) : (
            <div style={{ textAlign: 'center', padding: '20px' }}>The selected file is not a PDF.</div>
          )}
        </ModalBody>
      </ResizableBox>
    </Modal>
  );
};

export default PDFModalViewer;
