import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { api } from "../../config";

import PDFModalViewer from '../../Components/Common/PDFModalViewer'; 


const BlogByCat = ({ catID }) => {
    const { data: blogData, isLoading: isBlogLoading, isError: isBlogError } = useQuery({
        queryKey: ['blogQuery', catID],
        queryFn: async () => {
            const clientID = sessionStorage.getItem('clientID');
            const sessionloginKey = sessionStorage.getItem('loginKey');
            if (!clientID || !sessionloginKey) throw new Error('Missing clientID or sessionloginKey');

            const response = await axios.get(`${api.API_URL}/inc_dashboard_data.cfm?action=qBlogByCat&catID=${catID}&clientID=${clientID}`, {
                headers: { 'X-User-GUID': sessionloginKey, 'Authorization': sessionloginKey }
            });
            return response.data?.length ? response.data : [];
        },
        enabled: !!catID,
    });

  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false); 
  const [pdfData, setPdfData] = useState({ filePath: '', title: '' });

  const openPDF = (filePath, title) => {
    setPdfData({ filePath, title });
    setIsPDFViewerOpen(true); 
  };

  const closePDFModal = () => {
    setIsPDFViewerOpen(false);
    setPdfData({ filePath: '', title: '' }); 
  };



    return (
        <>
            <Card>              
                <CardBody>
                    <SimpleBar style={{ height: "300px" }} className="px-3 mx-n3 mb-2">
                    {isBlogLoading ? (
                            <Spinner>Loading...</Spinner>
                        ) : isBlogError ? (
                            <div>Error loading data...</div>
                        ) : blogData.length > 0 ? (
                            blogData.map((blog, index) => (
                                <div className="border rounded border-dashed p-2" key={index}>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-light text-secondary rounded fs-24">
                                                <Link to="#" onClick={() => openPDF(`${api.WEB_URL}/${blog.fileUpload}`, blog.title)}>  <i className="bx bxs-file-pdf text-danger"></i> 
                                                </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="fs-15 mb-1">
                                                <Link to="#" onClick={() => openPDF(`${api.WEB_URL}/${blog.fileUpload}`, blog.title)}>
                                                    <h6>{blog.title}</h6>
                                                </Link>
                                            </h5>
                                            <div className="d-flex flex-wrap gap-2 fs-16">
                                                <p className="fs-11 mb-0">{new Date(blog.blogDate).toLocaleDateString('en-AU')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No Morning Research Note Available</p>
                        )}
                    </SimpleBar>
                </CardBody>
            </Card>

            <PDFModalViewer
        isOpen={isPDFViewerOpen}
        toggleModal={closePDFModal}
        file={pdfData.filePath}
        modalTitle={pdfData.title}
      />   
        </>
    );
};

export default BlogByCat;
