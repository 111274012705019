import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';  
import axios from 'axios';
import { api } from "../../config";

import BlogByCat from "../../Components/Common/BlogByCat"

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

//SimpleBar
import SimpleBar from "simplebar-react";
import { Link } from 'react-router-dom';

const fetchFeatured = async ({ queryKey }) => {
    const clientID = sessionStorage.getItem('clientID');
    const sessionloginKey = sessionStorage.getItem('loginKey');
    const permissionsJSON = sessionStorage.getItem('permissions');
    const sessionPermissions = JSON.parse(permissionsJSON);
    const permissionList = sessionPermissions.join(',');
    if (!clientID || !sessionloginKey) {
      throw new Error('Missing clientID or sessionloginKey');
    }
    const response = await axios.get(api.API_URL + '/inc_dashboard_data.cfm', {
      params: {
        action: 'qFeatured',
        fileStream: permissionList,
        fileAccess: 'F',
        clientID: clientID
      },
      headers: {
        'X-User-GUID': sessionloginKey,
        'Authorization': sessionloginKey
      }
    });
    if (response.data && response.data.length > 0) {
      return response.data; // return the entire array so .map() works
    } 
};

const Featured = () => {
  const { data: FeaturedData, isLoading, isError } = useQuery({
      queryKey: ['FeaturedQuery'],
      queryFn: fetchFeatured,
      refetchOnWindowFocus: true
  });
  
  const [videoModal, setVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  
  const openModal = (feature) => {
    setSelectedVideo(feature);
    setVideoModal(true);
  };
  
  const closeModal = () => {
    setSelectedVideo(null);
    setVideoModal(false);
  };
  
  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  if (isError) {
    return <div>Error loading data</div>;
  }
  
  return (
    <React.Fragment>
      <Row>
        <Col xxl={9}>
          <div className="d-flex pt-2 mb-n4">
            <h5 className="card-title fs-18 mb-0">Your Latest Webinars</h5>
          </div>
          <Swiper modules={[Navigation, Autoplay]}
              slidesPerView={1}
              spaceBetween={10}
              navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
              }}
              breakpoints={{
                  640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                  },
                  768: {
                      slidesPerView: 2,
                      spaceBetween: 24,
                  },
                  1024: {
                      slidesPerView: 3,
                      spaceBetween: 24,
                  },
              }}
              loop={true}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              className="mySwiper marketplace-swiper rounded gallery-light pt-5">
            <div className="swiper-wrapper">
              {FeaturedData.map((feature, key) => (  
                <SwiperSlide key={key}>
                  <div className="card explore-box card-animate rounded">
                    <div className="explore-place-bid-img">
                      <img src={`${api.WEB_URL}/${feature.fileThumb}`} alt="" className="img-fluid card-img-top explore-img" />
                      <div className="bg-overlay"></div>
                      <div className="place-bid-btn">
                        {feature.fileType === "media_centre" ? (
                          <button className="btn btn-success" onClick={() => openModal(feature)}>
                            <i className="ri-video-fill align-bottom me-1"></i> Watch
                          </button>
                        ) : (
                          <Link to={feature.onlineLink} className="btn btn-success">
                            <i className="ri-video-fill align-bottom me-1"></i> Watch
                          </Link>
                        )}
                      </div>
                    </div>
                    <CardBody>                                        
                      <h5 className="mb-1">
                        {feature.fileType === "media_centre" ? (
                          <button className="btn btn-link p-0 text-body" onClick={() => openModal(feature)}>
                            {feature.fileTitle}
                          </button>
                        ) : (
                          <Link to={feature.onlineLink} className="text-body">
                            {feature.fileTitle}
                          </Link>
                        )}
                      </h5>
                    </CardBody>                                    
                  </div>
                </SwiperSlide>
              ))}
            </div>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </Swiper>
        </Col>
        <Col xxl={3}>
                    <div className="card explore-box card-animate rounded">
                    <h4 className="card-title mb-0 flex-grow-1">Morning Research Note</h4>
                    <BlogByCat catID="54" />
                    </div>
        </Col>
      </Row>
      <Modal isOpen={videoModal} toggle={closeModal} size="lg">
        <ModalHeader toggle={closeModal}>
          {selectedVideo && selectedVideo.fileTitle}
        </ModalHeader>
        <ModalBody>
          {selectedVideo && (
            <iframe
              width="100%"
              height="400px"
              src={selectedVideo.onlineLink}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title={selectedVideo.fileTitle}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Featured;
