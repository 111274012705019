import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { api } from "../../config";

const CompanyFinancials = ({ Country, Symbol }) => {
    const { data: financialData, isLoading, isError } = useQuery({
        queryKey: ['financialData', Country, Symbol],
        queryFn: async () => {
            const clientID = sessionStorage.getItem('clientID');
            const sessionloginKey = sessionStorage.getItem('loginKey');
            if (!clientID || !sessionloginKey) throw new Error('Missing clientID or sessionloginKey');

            const response = await axios.get(`${api.API_URL}/inc_chart_data.cfm?action=qFinancial&c=${Country}&s=${Symbol}&clientID=${clientID}`, {
                headers: { 'X-User-GUID': sessionloginKey, 'Authorization': sessionloginKey }
            });
            return response.data?.length ? response.data : [];
        },
        enabled: !!Country && !!Symbol,
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error loading financial data!</div>;
    }

    // Filter data based on Forder > 1 and LineOrRow = "ROW"
    const filteredData = financialData.filter(row => row.Forder > 1 && row.LineOrRow === "ROW");

    // Find the header row where Forder = 1
    const headerRow = financialData.find(row => row.Forder === 1 && row.LineOrRow === "ROW");

    return (
        <table className="table table-striped table-condensed">
            <thead>
                <tr>
                    {/* Dynamically render the headers from Forder 1 */}
                    <th>{headerRow?.Label2 || ''}</th>
                    <th>{headerRow?.Label3 || ''}</th>
                    <th>{headerRow?.Year_H2 || ''}</th>
                    <th>{headerRow?.Year_H1 || ''}</th>
                    <th>{headerRow?.Year_H0 || ''}</th>
                    <th>{headerRow?.Year_E1 || ''}</th>
                    <th>{headerRow?.Year_E2 || ''}</th>
                    <th>{headerRow?.Year_E3 || ''}</th>
                </tr>
            </thead>
            <tbody>
                {filteredData.map((row, index) => (
                    <tr key={row.Symbol}> {/* Unique identifier as key */}
                        <td>
                            {/* Make Label1 bold if it has a value */}
                            <strong>{row.Label1 || ''}</strong>
                            <div style={{ paddingLeft: '15px' }}>{row.Label2 || ''}</div>
                        </td>
                        <td>{row.Label3 || ''}</td>
                        <td>{row.Year_H2 || ''}</td>
                        <td>{row.Year_H1 || ''}</td>
                        <td>{row.Year_H0 || ''}</td>
                        <td><strong>{row.Year_E1 || ''}</strong></td>
                        <td><strong>{row.Year_E2 || ''}</strong></td>
                        <td><strong>{row.Year_E3 || ''}</strong></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default CompanyFinancials;
