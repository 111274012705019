// Routes/axiosConfig.js
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useAxiosInterceptor = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log("Axios interceptor caught an error:", error);
        // Fallback check: since error is an object, using error.toString() to check for "401"
        if (error.toString().includes("401")) {
          console.log("Unauthorized (fallback): redirecting to login");
          sessionStorage.clear();
          navigate("/login", { state: { sessionExpired: true } });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);
};

export default useAxiosInterceptor;
