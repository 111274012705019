import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import TableReportASR from '../../Components/Common/TableReportASR';
import ASRTrade from './ASRTrade';
import asrLogo from "../../assets/images/ASRLogo.svg";

const ASRClosed = ({ streamCode, uFolder }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const ExpandRows = 1;
  const columns = [
    {
      header: "",
      cell: (cell) => {
        const logoFile = cell.getValue();
        const svgUrl = `https://cdncontent.halotechnologies.com.au/images/LOGOS-SVG/${logoFile}`; 
        const pngBase = `https://cdncontent.halotechnologies.com.au/images/LOGOS-MASTER/${logoFile}`;        
        const pngUrl = pngBase.replace('.svg', '.png'); // Replace the extension to fallback to PNG
        
        return (
          <div 
            className="d-flex align-items-center justify-content-center" 
            style={{ 
              width: '50px', 
              height: '50px', 
              backgroundImage: `url(${asrLogo})`, 
              backgroundSize: 'cover', 
              verticalAlign: 'middle',
              backgroundPosition: 'center', 
              backgroundRepeat: 'no-repeat' // Ensures the background image does not repeat
            }}
          >
            <object 
              data={svgUrl} 
              type="image/svg+xml" 
              width="50" 
              height="50"
              style={{ display: 'block', maxWidth: '100%', maxHeight: '100%' }}
            >
              <img 
                src={pngUrl} 
                alt={cell.row.original.Symbol || 'Logo'} 
                style={{ width: '50px', height: 'auto', display: 'block', margin: 'auto' }}
                onError={(e) => { 
                  e.target.onerror = null; 
                  e.target.style.visibility = 'hidden'; // Hide the image if it fails
                }} 
              />
            </object>
          </div>
        );
      },
      accessorKey: "LogoFile",
      enableColumnFilter: false,
    },
    {
      header: () => <div className="center-align">Symbol</div>,
      accessorKey: "Symbol", // Column name in data
      enableColumnFilter: false,
      cell: (cell) => {
        const symbol = cell.getValue(); // Get the symbol for the row
        const tradeID = cell.row.original.tradeID.replace(/,/g, ''); // Clean up the tradeID
        const name = cell.row.original.Name; // Assuming the Name column is available on the same row
    
        // Pass the name as tradeTitle and symbol as Symbol
        return <ASRTrade tradeTitle={symbol} name={name} tradeID={tradeID} Symbol={symbol} uFolder={uFolder} />;
      },
    },
    !isMobile && {
      header: "Name",
      cell: (cell) => {
        const name = cell.getValue(); // Get the Name for the row
        const tradeID = cell.row.original.tradeID.replace(/,/g, ''); // Clean up the tradeID
        const symbol = cell.row.original.Symbol; // Assuming the Symbol column is available on the same row
    
        // Pass the name as tradeTitle and symbol as Symbol
        return <ASRTrade tradeTitle={name} name={name} tradeID={tradeID} Symbol={symbol} uFolder={uFolder} />;
      },
      accessorKey: "Name", // Column name in data
      enableColumnFilter: false,
    },
    {
      header: () => <div className="center-align">ASR Rating</div>,
      accessorKey: "ASR_Rating",
      enableColumnFilter: false,
      cell: (cell) => <div className="center-align">{cell.getValue()}</div>,
    },
    !isMobile &&{
      header: "Sector",
      accessorKey: "Sector",
      enableColumnFilter: false,
    },
    !isMobile &&{
      header: () => <div className="center-align">Size</div>,
      accessorKey: "Size",
      enableColumnFilter: false,
      cell: (cell) => <div className="center-align">{cell.getValue()}</div>,
    },
    !isMobile &&{
      header: () => <div className="center-align">Volatility</div>,
      accessorKey: "Volatility",
      enableColumnFilter: false,
      cell: (cell) => {
        switch (cell.getValue()) {
          case "Low":
            return (<div className="center-align"><span className="badge bg-success-subtle text-success text-uppercase"> {cell.getValue()}</span></div>);
          case "Medium":
            return (<div className="center-align"><span className="badge bg-warning-subtle  text-warning text-uppercase"> {cell.getValue()}</span></div>);
          case "High":
            return (<div className="center-align"><span className="badge bg-danger-subtle  text-danger text-uppercase"> {cell.getValue()}</span></div>);
          case "Very High":
              return (<div className="center-align"><span className="border border-danger text-danger badge text-uppercase"> {cell.getValue()}</span></div>);
          default:
            return (<div className="center-align"><span className="badge bg-light-subtle text-uppercase"> {cell.getValue()}</span></div>);
        }
      },
    },
    {
      header: () => <div className="center-align">Featured</div>,
      accessorKey: "Featured",
      enableColumnFilter: false,
      cell: (cell) => {
        const date = new Date(cell.getValue());
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, '-'); // Replace spaces with dashes to get d-M-Y format
    
        return <div className="center-align">{formattedDate}</div>;
      },
    },
    {
      header: () => <div className="center-align">Exit Date</div>,
      accessorKey: "exitDate",
      enableColumnFilter: false,
      cell: (cell) => {
        const date = new Date(cell.getValue());
        const formattedExitDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, '-'); // Replace spaces with dashes to get d-M-Y format
    
        return <div className="center-align">{formattedExitDate}</div>;
      },
    },
    {
      header: () => <div className="center-align">Start Price</div>,
      cell: (cell) => {
        const row = cell.row.original;
        const price = cell.getValue();
        const currencyCode = row.ISO_Currency || 'USD'; // Assuming 'USD' as the default currency code
        const currencySymbol = currencyCode === 'AUD' ? '' : row.currencySymbol || '$';
  
        const formattedPrice = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currencyCode
        }).format(price);
  
        return `${currencySymbol}${formattedPrice}`;
      },
      accessorKey: "StartPrice",
      enableColumnFilter: false,
      cell: (cell) => <div className="center-align">{cell.getValue()}</div>,
    },
    {
      header: () => <div className="center-align">Exit Price</div>,
      cell: (cell) => {
        const row = cell.row.original;
        const price = cell.getValue();
        const currencyCode = row.ISO_Currency || 'USD'; // Assuming 'USD' as the default currency code
        const currencySymbol = currencyCode === 'AUD' ? '' : row.currencySymbol || '$';
  
        const formattedPrice = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currencyCode
        }).format(price);
  
        return `${currencySymbol}${formattedPrice}`;
      },
      accessorKey: "exitLevel",
      enableColumnFilter: false,
      cell: (cell) => <div className="center-align">{cell.getValue()}</div>,
    },
    {
      header: () => <div className="center-align">Dividendsn</div>,
      accessorKey: "Dividend_Return",
      enableColumnFilter: false,
      cell: (cell) => <div className="center-align">{cell.getValue()}</div>,
    },
    {
      header: () => <div className="center-align">Return</div>,
      accessorKey: "Total_Return",
      enableColumnFilter: false,
      cell: (cell) => {
        let totalReturn = cell.getValue();
    
        // Remove the '%' sign and convert to a number
        if (typeof totalReturn === 'string') {
          totalReturn = parseFloat(totalReturn.replace('%', ''));
        }
    
        if (isNaN(totalReturn)) {
          return <div className="center-align text-muted">N/A</div>; // Handle invalid values
        }
    
        if (totalReturn < 0) {
          return (<div className="center-align text-danger">{cell.getValue()}</div>);
        } else if (totalReturn === 0) {
          return (<div className="center-align">{cell.getValue()}</div>);
        } else if (totalReturn > 0) {
          return (<div className="center-align text-success">{cell.getValue()}</div>);
        }
      },
    },

  ].filter(Boolean);

  return (
    <React.Fragment>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Closed Trades</h5>
                </CardHeader>
                <CardBody>
                  <TableReportASR stream={streamCode} position="closed" columns={columns} ExpandRows={ExpandRows} />
                </CardBody>
              </Card>
    </React.Fragment>
  );
}

export default ASRClosed;
