import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { api } from "../../config";

const fetchSparklineData = async ({ queryKey }) => {
  const [, country, symbol] = queryKey;

  const clientID = sessionStorage.getItem('clientID');
  const sessionloginKey = sessionStorage.getItem('loginKey');

  try {
    const response = await axios.get(api.API_URL + `/inc_chart_data.cfm?action=qReports&c=${country}&s=${symbol}&clientID=${clientID}&d=12`, {
      headers: {
        'X-User-GUID': sessionloginKey,
        'Authorization': sessionloginKey
      }
    });

    return response.data; // Assuming this returns an array of data points
} catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized: Invalid login key');
      window.location.href = '/login'; // Redirect to login page
    } else {
      console.error('An error occurred:', error.message);
      throw new Error('Data Error'); // Throw generic error for UI handling
    }
  }
};

const SparklineChart = ({ country, symbol }) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ['sparklineData', country, symbol],
    queryFn: fetchSparklineData,
  });

  if (isLoading) return <div>Loading sparkline...</div>;
  if (isError) return <div>Error loading sparkline: {error.message}</div>;
  if (!data || data.length === 0) return <div>No sparkline data available</div>;

  // Extract the Close prices and AsAtDates for the sparkline
  const sparklineData = data.map(item => ({
    date: item.AsAtDate,
    value: item.Close,
    open: item.Open,
    high: item.High,
    low: item.Low,
    close: item.Close,
    volume: item.Volume,
  }));

  // Determine the color based on the price comparison
  const firstPrice = sparklineData[0].value;
  const lastPrice = sparklineData[sparklineData.length - 1].value;
  const color = lastPrice > firstPrice ? '#00E396' : '#FF4560'; // Green if last price is higher, red if lower

  // Define chart options and data
  const chartOptions = {
    chart: {
        width: 130,
        height: 46,
        type: "area",
        sparkline: {
            enabled: true,
        },
        toolbar: {
            show: false,
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: "smooth",
        width: 1.5,
    },
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [50, 100, 100, 100],
        },
    },
    colors: [color], // Use dynamic color
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        const dataPoint = sparklineData[dataPointIndex];
        return `
          <div className="fw-lighter" style="padding: 5px;">
            Date: ${dataPoint.date}<br/>           
            Close: ${dataPoint.close}<br/>
          </div>
        `;
      }
    }
  };

  const chartSeries = [{
    name: 'Close Price',
    data: sparklineData.map(item => item.value) // The Close prices for the sparkline
  }];

  return (
    <div>
      <ReactApexChart 
        options={chartOptions} 
        series={chartSeries} 
        type="area" 
        height={70} // Adjust height as needed
      />
    </div>
  );
};

export default SparklineChart;
