import React, { useState } from 'react';
import classnames from "classnames";
import { Link } from 'react-router-dom';
import {Modal, ModalHeader, ModalBody,ModalFooter, ListGroup, ListGroupItem, Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, Accordion, AccordionItem, Spinner, Offcanvas, OffcanvasHeader, OffcanvasBody, Collapse } from 'reactstrap'; 
import SimpleBar from 'simplebar-react';
import { useQuery } from '@tanstack/react-query';  
import axios from 'axios';
import { api } from "../../config";
import PDFModalViewer from '../../Components/Common/PDFModalViewer'; 
import FlashReports from '../../Components/Common/FlashReports';
import CompanyFinancials from '../../Components/Common/CompanyFinancials';
import StockData from '../../Components/Common/StockData';

// Function to fetch trade data  4386
const fetchTradeData = async ({ queryKey }) => {
  const [, tradeID] = queryKey; 
  const clientID = sessionStorage.getItem('clientID');
  const sessionloginKey = sessionStorage.getItem('loginKey');

  if (!clientID || !sessionloginKey) {
    throw new Error('Missing clientID or sessionloginKey');
  }

  try {
    const response = await axios.get(api.API_URL + `/inc_report_data.cfm?action=qTrade&s=${tradeID}&clientID=${clientID}`, {
        headers: {
            'X-User-GUID': sessionloginKey,
            'Authorization': sessionloginKey
          }
    });

    if (response.data && response.data.length > 0) {
      return response.data[0]; 
    } else {
      throw new Error('No data available');
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized: Invalid login key');
      window.location.href = '/login'; 
    } else {
      console.error('An error occurred:', error.message);
      throw new Error('Data Error');
    }
  }
};



const ASRTrade = ({ tradeTitle, tradeID, Symbol, uFolder }) => {
  const [isRight, setIsRight] = useState(false);
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false); 
  const [pdfData, setPdfData] = useState({ filePath: '', title: '' });

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const openPDF = (filePath, title) => {
    setPdfData({ filePath, title });
    setIsPDFViewerOpen(true); 
  };

  const closePDFModal = () => {
    setIsPDFViewerOpen(false);
    setPdfData({ filePath: '', title: '' }); 
  };

  const [activityTab, setActivityTab] = useState('1');


  const toggleActivityTab = (tab) => {
    if (activityTab !== tab) {
        setActivityTab(tab);
    }
  };

  const { data: tradeData, isLoading, isError } = useQuery({
    queryKey: ['tradeQuery', tradeID], 
    queryFn: fetchTradeData,
    enabled: isRight,
  });


    return (
      <React.Fragment>
        <div>
          {/* The clickable tradeTitle (Symbol or Name) */}
          <span onClick={toggleRightCanvas} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            {tradeTitle}
          </span>
  
          {/* Offcanvas component */}
          <Offcanvas className="offcanvas-custom" isOpen={isRight} toggle={toggleRightCanvas} direction="end" id="offcanvasRightLabel">
            <OffcanvasHeader toggle={toggleRightCanvas}>
              {tradeData ? (
                ''
              ) : (
                `Error Loading Data`
              )}
            </OffcanvasHeader>
            <OffcanvasBody className="p-0 overflow-hidden">
              <SimpleBar style={{ height: "100vh" }}>
                  {tradeData ? (
                    <div className="mb-lg-3">
                    <Row className="g-0">
                        <div className="col-auto px-5">
                            <div className="img-fluid">
                              <object
                                data={`https://cdncontent.halotechnologies.com.au/images/LOGOS-SVG/${tradeData.LogoFile}`}
                                type="image/svg+xml"
                                height="90"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                }}
                              >
                                <img
                                  src={`https://cdncontent.halotechnologies.com.au/images/LOGOS-MASTER/${tradeData.LogoFile.replace('.svg', '.png')}`}
                                  alt={tradeData.Symbol}
                                  style={{
                                    height: '90px',
                                  }}
                                />
                              </object>
                            </div>
                        </div>

                        <Col>
                          <div className="position-relative">
                                <h3 className="fs-2 text mb-1">{tradeData.Name}</h3>
                                <p className="text-opacity-75"><p className="mb-0 fs-3 text">{tradeData.currencyString} {tradeData.lastPrice} {tradeData.Price_Return !== undefined && ( 
          <span className={`${tradeData.Price_Return < 0 ? 'text-danger' : tradeData.Price_Return > 0 ? 'text-success' : ''}`}>{tradeData.Price_Return.toFixed(2)}%</span>
        )}<span className="text-muted ms-2 text-uppercase">({tradeData.Symbol})</span></p></p>                                
                            </div>
                        </Col>
                        <Col lg={4} xs={12} className="col-lg-auto order-last order-lg-0">
                                <Row className="text text-center">
                                    <Col lg={6} xs={4}>
                                    <Card>
                                        <CardBody>
                                        <h5 className="mb-1">{tradeData.Featured}</h5>
                                        <p className="fs-11 mb-0">Featured</p>
                                        </CardBody>
                                    </Card>
                                    </Col>
                                    <Col lg={6} xs={4}>
                                      <Card>
                                          <CardBody>
                                          <h5 className="mb-1">${tradeData.StartPrice}</h5>
                                          <p className="fs-11 mb-0">Start Price</p>
                                          </CardBody>
                                      </Card>
                                    </Col>
                                </Row>
                            </Col>
                    </Row>
                    <Row>
                    <Col lg={12}>
                    <ListGroup className="justify-content-center mb-3" horizontal>
                        <ListGroupItem className='badge bg-dark-subtle text-primary m-1'><h5 className=" mb-1">{tradeData.ASR_Rating}</h5>
                        <p className="fs-11 mb-0">ASR Rating</p></ListGroupItem>
                        <ListGroupItem className='badge bg-dark-subtle text-primary m-1'><h5 className=" mb-1">{tradeData.Sector}</h5>
                        <p className="fs-11 mb-0">Sector</p></ListGroupItem>
                        <ListGroupItem className='badge bg-dark-subtle text-primary m-1'><h5 className=" mb-1">{tradeData.Size}</h5>
                        <p className="fs-11 mb-0">Size</p></ListGroupItem>
                        <ListGroupItem className='badge bg-dark-subtle text-primary m-1'><h5 className=" mb-1">{tradeData.Volatility}</h5>
                        <p className="fs-11 mb-0">Volatility</p></ListGroupItem>
                        <ListGroupItem className='badge bg-dark-subtle text-primary m-1'><h5 className=" mb-1">{tradeData.targetPrice}</h5>
                        <p className="fs-11 mb-0">Consensus Target Price</p></ListGroupItem>
                        <ListGroupItem className='badge bg-dark-subtle text-primary m-1'><h5 className=" mb-1">{tradeData.targetPriceDeviation}</h5>
                        <p className="fs-11 mb-0">Deviation</p></ListGroupItem>
                        <ListGroupItem className='badge bg-dark-subtle text-primary m-1'><h5 className=" mb-1">{tradeData.Divs}</h5>
                        <p className="fs-11 mb-0">Dividends</p></ListGroupItem>
                        <ListGroupItem className='badge bg-dark-subtle text-primary m-1'><h5 className=" mb-1">{tradeData.Dividend_Return}</h5>
                        <p className="fs-11 mb-0">Dividend Return</p></ListGroupItem>
                        <ListGroupItem className='badge bg-dark-subtle text-primary m-1'><h5 className=" mb-1">{tradeData.Capital_Return}</h5>
                        <p className="fs-11 mb-0">Capital Return</p></ListGroupItem>
                        <ListGroupItem className='badge bg-dark-subtle text-primary m-1'><h5 className=" mb-1">{tradeData.Total_Return}</h5>
                        <p className="fs-11 mb-0">Total Return</p></ListGroupItem>
                    </ListGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Card>
                          <CardBody>
                          <div dangerouslySetInnerHTML={{ __html: tradeData.aboutCompany }} /> 
                          </CardBody>
                        </Card>
                    <Col lg={4} xs={12}>
                                                
                            {tradeData.chart && tradeData.chart.toLowerCase().endsWith('.pdf') && (
                                <Card>
                                    <CardBody>
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="flex-grow-1">
                                        <h5 className="card-title mb-0">Download</h5>
                                        </div>                                                            
                                    </div>
                                    <div className="d-flex mb-4">
                                        <div className="flex-shrink-0">
                                        {/* Add any icons or images here if needed */}
                                        </div>
                                        <div className="flex-grow-1 ms-3 overflow-hidden">
                                        <Link to="#" onClick={() => openPDF(`https://report.australianstockreport.com.au/msr/image/upload/${uFolder}/${tradeData.chart}`, tradeData.Name)}>
                                            <h6>{tradeData.chart}</h6>
                                        </Link>
                                        <p className="text-muted mb-0 fs-13"></p>
                                        </div>
                                    </div>
                                    </CardBody>
                                </Card>
                                )}

                  {tradeData.blog_count > 0 && (
                        <FlashReports Symbol={tradeData.Symbol} Country={tradeData.country} />
                    )}
                    </Col>
                    <Col xs={8}>
        <Card>
            <CardHeader className="align-items-center d-flex">
                <div className="flex-shrink-0 w-100">
                    <Nav
                        className="justify-content nav-tabs-custom rounded card-header-tabs border-bottom-0 w-100"
                        role="tablist"
                    >
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activityTab === '1' })}
                                onClick={() => { toggleActivityTab('1'); }}
                            >
                                <h5 className="card-title mb-0 me-2">Overview</h5>
                            </NavLink>
                        </NavItem>

                        {tradeData.ASRViews && tradeData.ASRViews.trim() !== "" && (
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: activityTab === '2' })}
                                    onClick={() => { toggleActivityTab('2'); }}
                                >
                                    <h5 className="card-title mb-0 me-2">Investment Thesis</h5>
                                </NavLink>
                            </NavItem>
                        )}

                        {tradeData.catalyst && tradeData.catalyst.trim() !== "" && (
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: activityTab === '3' })}
                                    onClick={() => { toggleActivityTab('3'); }}
                                >
                                    <h5 className="card-title mb-0 me-2">Catalyst</h5>
                                </NavLink>
                            </NavItem>
                        )}

                        {tradeData.downsideRisk && tradeData.downsideRisk.trim() !== "" && (
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: activityTab === '4' })}
                                    onClick={() => { toggleActivityTab('4'); }}
                                >
                                    <h5 className="card-title mb-0 me-2">Downside Risk</h5>
                                </NavLink>
                            </NavItem>
                        )}

                        <NavItem className="nav-item">
                            <NavLink
                                href="#"
                                className={classnames({ active: activityTab === '5' })}
                                onClick={() => { toggleActivityTab('5'); }}
                            >
                                <h5 className="card-title mb-0 me-2">Financials</h5>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </CardHeader>

            <CardBody>
                <TabContent activeTab={activityTab} className="text-muted w-100">
                    <TabPane tabId="1">
                        <StockData c={tradeData.country} s={tradeData.Symbol} isMobile='1' />
                    </TabPane>

                    {tradeData.ASRViews && tradeData.ASRViews.trim() !== "" && (
                        <TabPane tabId="2">
                            <div dangerouslySetInnerHTML={{ __html: tradeData.ASRViews }} />
                        </TabPane>
                    )}

                    {tradeData.catalyst && tradeData.catalyst.trim() !== "" && (
                        <TabPane tabId="3">
                            <div dangerouslySetInnerHTML={{ __html: tradeData.catalyst }} />
                        </TabPane>
                    )}

                    {tradeData.downsideRisk && tradeData.downsideRisk.trim() !== "" && (
                        <TabPane tabId="4">
                            <div dangerouslySetInnerHTML={{ __html: tradeData.downsideRisk }} />
                        </TabPane>
                    )}

                    <TabPane tabId="5">
                        <CompanyFinancials Country={tradeData.country} Symbol={tradeData.Symbol} />
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
</Col>

                    </Row>
                  
                    </div>
                    
                   
                  ) : (
                    <p>Loading trade data...</p>
                  )}
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>
         
        </div>
        <PDFModalViewer
        isOpen={isPDFViewerOpen}
        toggleModal={closePDFModal}
        file={pdfData.filePath}
        modalTitle={pdfData.title}
      />           
      </React.Fragment>
    );
  };
  
  export default ASRTrade;
