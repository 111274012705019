import React from "react";
import {Container } from "reactstrap";


import StockData from '../../Components/Common/StockData';
import StockInfo from '../../Components/Common/StockInfo';
import Featured from "./Featured"

const Dashboard = () => {
  document.title = "Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <StockData c='AU' s='ASX' isMobile='0' />
        <StockInfo country='AU' symbol='ASX' />
        <Featured />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
