import React from 'react';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';
import useAxiosInterceptor from './Routes/axiosConfig';


import { 
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
// Activating fake backend
//fakeBackend();


function App() {
  const queryClient = new QueryClient()
  useAxiosInterceptor();
  return (
    <QueryClientProvider client={queryClient}>
    <React.Fragment>
      <Route />
    </React.Fragment>
    </QueryClientProvider>
  );
}

export default App;
