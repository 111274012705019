module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "https://report.australianstockreport.com.au/actions/api",
    WEB_URL: "https://report.australianstockreport.com.au",
  },
};