import React from "react";
import { Navigate } from "react-router-dom";

import Dashboard from "../pages/Dashboard";

//pages
import Maintenance from '../pages/Pages/Maintenance/Maintenance';

//reports
import Investing from '../pages/Reports/investing/';
import Income from '../pages/Reports/income/';
import ETF from '../pages/Reports/etf/';
import Resources from '../pages/Reports/resources/';
import SMSF from '../pages/Reports/smsf/';
import PATS from '../pages/Reports/pats';


//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";




const authProtectedRoutes = [  
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/index", component: <Dashboard /> },  

  //REPORTS
  //Investing Report
  { path: "/report/investing", component: <Investing /> },  
  //Income Report
  { path: "/report/income", component: <Income /> },
  //ETF Report
  { path: "/report/etf", component: <ETF /> },
  //Resources Portfolio
  { path: "/report/resources", component: <Resources /> },
  //SMSF Portfolio
  { path: "/report/smsf", component: <SMSF /> },
  //PATS
  { path: "/report/pats", component: <PATS /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },

  
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <Maintenance /> },

];

export { authProtectedRoutes, publicRoutes };