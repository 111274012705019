import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { api } from "../../config";

const FlashReports = ({ Symbol, Country }) => {
    const { data: blogData, isLoading: isBlogLoading, isError: isBlogError } = useQuery({
        queryKey: ['blogQuery', Symbol, Country],
        queryFn: async () => {
            const clientID = sessionStorage.getItem('clientID');
            const sessionloginKey = sessionStorage.getItem('loginKey');
            if (!clientID || !sessionloginKey) throw new Error('Missing clientID or sessionloginKey');

            const response = await axios.get(`${api.API_URL}/inc_report_data.cfm?blog=true&s=${Symbol}&c=${Country}&clientID=${clientID}`, {
                headers: { 'X-User-GUID': sessionloginKey, 'Authorization': sessionloginKey }
            });
            return response.data?.length ? response.data : [];
        },
        enabled: !!Symbol && !!Country,
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const printRef = useRef();

    const toggleModal = (blog = null) => {
        setSelectedBlog(blog);   // Set the blog to display in the modal
        setModalOpen(!modalOpen); // Toggle the modal visibility
    };

    const handlePrint = () => {
        // Create a temporary print window
        const printWindow = window.open('', '_blank');
        
        // Build the content to print
        const printContent = `
            <div>
                <h1>${selectedBlog.title}</h1>
                <div>${selectedBlog.description}</div>
            </div>
        `;
    
        // Set the content inside the new print window
        printWindow.document.write(`<html><head><title>${selectedBlog.title}</title></head><body>`);
        printWindow.document.write(printContent);
        printWindow.document.write('</body></html>');
    
        // Close the document to finish writing content
        printWindow.document.close();
    
        // Wait for the content to load and then trigger the print dialog
        printWindow.onload = () => {
            printWindow.print();
            
            // Delay closing the window to allow the print dialog to appear
            setTimeout(() => {
                printWindow.close(); // Close the print window after printing
            }, 1000); // Adjust the delay if necessary
        };
    
        // Close the modal after printing
        setModalOpen(false);
    };
    
    
    const handleDownloadPDF = () => {
        // Custom styles to control the appearance of the content
        const content = `
            <div style="font-family: Arial, sans-serif; font-size: 12px; line-height: 1.6;">
                <h1 style="font-size: 16px; margin-bottom: 10px;">${selectedBlog.title}</h1>
                <div style="font-size: 12px;">${selectedBlog.description}</div>
            </div>
        `;
        
        const pdf = new jsPDF('p', 'mm', 'a4');
        
        // Render the HTML content inside the PDF
        pdf.html(content, {
            callback: function (pdf) {
                pdf.save(`${selectedBlog.title}.pdf`);
            },
            x: 10,  // Set left margin
            y: 10,  // Set top margin
            width: 180,  // Set width to fit the page content
            windowWidth: 800,  // Optional: set the window width for better rendering
        });
    };


    return (
        <>
            <Card>
                <CardHeader className="align-items-center d-flex border-bottom-dashed">
                    <h4 className="card-title mb-0 flex-grow-1">Flash Reports</h4>
                </CardHeader>
                <CardBody>
                    <SimpleBar style={{ height: "300px" }} className="px-3 mx-n3 mb-2">
                    {isBlogLoading ? (
                            <Spinner>Loading...</Spinner>
                        ) : isBlogError ? (
                            <div>Error loading data...</div>
                        ) : blogData.length > 0 ? (
                            blogData.map((blog, index) => (
                                <div className="border rounded border-dashed p-2" key={index}>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-light text-secondary rounded fs-24">
                                                    {blog.criteria === 'Flash' ? <i className="ri-flashlight-line"></i> : <i className="ri-file-text-line"></i>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="fs-15 mb-1">
                                                <Link to="#" className="text-body text-truncate d-block" onClick={() => toggleModal(blog)}>
                                                    {blog.title}
                                                </Link>
                                            </h5>
                                            <div className="d-flex flex-wrap gap-2 fs-16">
                                                <p className="fs-11 mb-0">{new Date(blog.blogDate).toLocaleDateString('en-AU')}</p>
                                                <p className="fs-11 mb-0">{blog.entity}</p>
                                                <p className="fs-11 mb-0">{blog.criteria}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No blog posts available</p>
                        )}
                    </SimpleBar>
                </CardBody>
            </Card>

            <Modal size="xl" isOpen={modalOpen} toggle={() => toggleModal()}>
    {selectedBlog ? (
        <>
            <ModalHeader toggle={() => toggleModal()}>{selectedBlog.title}</ModalHeader>
            <ModalBody ref={printRef}>
                <p><strong>Date:</strong> {new Date(selectedBlog.blogDate).toLocaleDateString('en-AU')}</p>
                <p><strong>Entity:</strong> {selectedBlog.entity}</p>
                <div dangerouslySetInnerHTML={{ __html: selectedBlog.description }} />
            </ModalBody>
            <div className="d-flex justify-content-end p-3 gap-2">
                <Button color="primary" onClick={handlePrint}>Print</Button>
                <Button color="success" onClick={handleDownloadPDF}>Download PDF</Button>
                <Button color="secondary" onClick={() => toggleModal()}>Close</Button>
            </div>
        </>
    ) : (
        <p>Loading...</p>
    )}
</Modal>
        </>
    );
};

export default FlashReports;
