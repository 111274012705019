import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

const StockChart = ({ data, bollingerBands, isMobile }) => {
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 800, height: 600 });

  // Make sure isMobile is passed correctly, and use it
  const margin = { top: isMobile ? 50 : 30, right: 60, bottom: 40, left: 70 };
  const chartWidth = dimensions.width - margin.left - margin.right;
  const chartHeight = dimensions.height - margin.top - margin.bottom;
  const dayPadding = 1; // 1px padding between days

  let newYScale = null;
  let newYVolumeScale = null;

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = svgRef.current.parentElement.clientWidth;
      setDimensions({
        width: containerWidth,
        height: 400,
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!data || data.length === 0) return;

    data.forEach(d => {
      if (!(d.AsAtDate instanceof Date)) {
        d.date = new Date(d.AsAtDate);
      } else {
        d.date = d.AsAtDate;
      }
    });

    // Filter out weekends (Saturday and Sunday)
    let validData = data.filter(d => !isNaN(d.date) && d.Close !== null && d.date.getDay() !== 0 && d.date.getDay() !== 6);

    if (validData.length === 0) {
      console.error('No valid data to render.');
      return;
    }

    const latestData = validData[validData.length - 1]; // Get the latest data point

 
    const validTPData = validData.filter(d => d.TP != null && d.TP !== "" && d.TP != 0);

    // Create an xScale using the index of the data
    const xScale = d3.scaleLinear()
      .domain([0, validData.length - 1])
      .range([0, chartWidth]);

    const yScale = d3.scaleLinear()
      .domain([
        d3.min(validData, d => d.Low) * 0.9,
        d3.max(validData, d => d.High) * 1.1
      ])
      .range([chartHeight, 0]);

    const yVolumeScale = d3.scaleLinear()
      .domain([0, d3.max(validData, d => d.Volume)])
      .range([(chartHeight / 2), 0]);

    // Initialize newYScale and newYVolumeScale with the initial scales
    newYScale = yScale;
    newYVolumeScale = yVolumeScale;

    const xAxis = d3.axisBottom(xScale)
    .ticks(7)
    .tickFormat((d, i, nodes) => {
      const correspondingDate = validData[d]?.date; // Get the date corresponding to the index `d`
      const previousDate = i > 0 ? validData[nodes[i - 1].__data__]?.date : null;
  
      if (correspondingDate) {
        // Format tick label with year if it's a new year
        if (previousDate && previousDate.getFullYear() !== correspondingDate.getFullYear()) {
          return d3.timeFormat('%d-%b-%y')(correspondingDate);
        } else if (!previousDate || previousDate.getFullYear() !== correspondingDate.getFullYear()) {
          return d3.timeFormat('%d-%b')(correspondingDate);
        } else {
          return d3.timeFormat('%d-%b')(correspondingDate);
        }
      }
      return ''; // Return an empty string if there's no corresponding date
    })
    .tickSize(-chartHeight);
  

    const yAxis = d3.axisLeft(yScale)
      .tickFormat(d3.format("$.2f"))
      .tickSize(-chartWidth);

    const yVolumeAxis = d3.axisRight(yVolumeScale)
      .ticks(yScale.ticks().length) // Match volume ticks with price ticks
      .tickFormat(d => `${d}M`); // Add 'M' to the volume ticks

    const svg = d3.select(svgRef.current)
      .attr('width', dimensions.width)
      .attr('height', dimensions.height);

    svg.selectAll('*').remove();

    svg.append('defs').append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', chartWidth)
      .attr('height', chartHeight);

    // After defining `g` and before appending the lines for the Bollinger Bands
    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .attr('clip-path', 'url(#clip)');

    // Define the Bollinger Bands area generator
    const bollingerArea = d3.area()
    .x(d => xScale(d.index)) // Use index for x positioning
    .y0(d => yScale(d.lower))
    .y1(d => yScale(d.upper));

    // Append the Bollinger Bands area
    g.append('path')
      .datum(bollingerBands)
      .attr('class', 'bollinger-area')
      .attr('fill', 'rgba(120, 212, 164, 0.11)')
      .attr('d', bollingerArea);

    // Now append the upper and lower lines for the Bollinger Bands
    const upperBandLine = g.append('path')
      .datum(bollingerBands)
      .attr('class', 'bollinger-upper')
      .attr('fill', 'none')
      .attr('stroke-dasharray', '5, 5')
      .attr('stroke', 'rgba(0, 108, 138, 0.21)')
      .attr('stroke-width', 1)
      .attr('d', d3.line()
        .defined(d => d.upper != null)
        .x((d, i) => xScale(i)) // Use index as x value
        .y(d => yScale(d.upper))
      );

    const lowerBandLine = g.append('path')
      .datum(bollingerBands)
      .attr('class', 'bollinger-lower')
      .attr('fill', 'none')
      .attr('stroke-dasharray', '5, 5')
      .attr('stroke', 'rgba(0, 108, 138, 0.21)')
      .attr('stroke-width', 1)
      .attr('d', d3.line()
        .defined(d => d.lower != null)
        .x((d, i) => xScale(i)) // Use index as x value
        .y(d => yScale(d.lower))
      );

    const getCandlestickWidth = (scale) => {
      const daysInRange = (scale.domain()[1] - scale.domain()[0]);
      return Math.max(1, chartWidth / daysInRange - dayPadding);
    };

    let candlestickWidth = getCandlestickWidth(xScale);

    // Render volume bars in the background
    const volumeBars = g.selectAll('.volume-bar')
  .data(validData)
  .enter().append('rect')
  .attr('class', 'volume-bar')
  .attr('x', (d, i) => xScale(i) - candlestickWidth / 2 + dayPadding / 2)
  .attr('width', candlestickWidth - dayPadding)
  .attr('y', d => (chartHeight / 2) + newYVolumeScale(d.Volume)) // Position in the bottom half
  .attr('height', d => (chartHeight / 2) - newYVolumeScale(d.Volume))
  //.attr('fill', 'rgba(52,125,173,0.15)')
  .attr('fill', d => d.Close > d.Open ? 'rgba(83,177,154,0.1)' : 'rgba(73,80,87,0.1)');

    const candlesticks = g.selectAll('.candlestick')
      .data(validData)
      .enter().append('g')
      .attr('class', 'candlestick')
      .attr('transform', (d, i) => `translate(${xScale(i)},0)`);

    candlesticks.append('rect')
      .attr('class', 'body')
      .attr('x', -candlestickWidth / 2 + dayPadding / 2)
      .attr('width', candlestickWidth - dayPadding)
      .attr('fill', d => d.Close > d.Open ? 'rgba(83,177,154,1)' : 'rgba(73,80,87,1)')
      .attr('y', d => yScale(Math.max(d.Open, d.Close)))
      .attr('height', d => Math.abs(yScale(d.Open) - yScale(d.Close)));

    candlesticks.append('line')
      .attr('class', 'high-low')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('stroke', d => d.Close > d.Open ? 'rgba(83,177,154,0.7)' : 'rgba(73,80,87,0.7)')
      .attr('stroke-width', '0.175em')
      .attr('y1', d => yScale(d.High))
      .attr('y2', d => yScale(d.Low));

    const maLine = g.append('path')
      .datum(bollingerBands)
      .attr('class', 'moving-avg-line')
      .attr('fill', 'none')
      .attr('stroke-dasharray', '5, 5')
      .attr('stroke', 'rgba(0, 108, 138, 0.21)')
      .attr('stroke-width', 1)
      .attr('d', d3.line()
        .defined(d => d.mean != null)
        .x((d, i) => xScale(i)) // Use index as x value
        .y(d => yScale(d.mean))
      );

    const tpLine = g.append('path')
      .datum(validTPData)
      .attr('class', 'tp-line')
      .attr('fill', 'none')
      .attr('stroke', 'rgba(255,255,0,0.7)') // Yellow color for TP line
      .attr('stroke-width', 2)
      .attr('d', d3.line()
        .defined(d => d.TP != null)
        .x((d, i) => xScale(i)) // Use index as x value
        .y(d => yScale(d.TP))
      );

    const ema8Line = g.append('path')
      .datum(data)
      .attr('class', 'ema-line')
      .attr('fill', 'none')
      .attr('stroke', 'darkgreen')  // Dark green for EMA8
      .attr('stroke-width', 1.5)
      .attr('d', d3.line()
        .defined(d => d.EMA8 != null)
        .x((d, i) => xScale(i)) // Use index as x value
        .y(d => yScale(d.EMA8))
      );

    const ema21Line = g.append('path')
      .datum(data)
      .attr('class', 'ema-line')
      .attr('fill', 'none')
      .attr('stroke', 'blue')  // Blue for EMA21
      .attr('stroke-width', 1.5)
      .attr('d', d3.line()
        .defined(d => d.EMA21 != null)
        .x((d, i) => xScale(i)) // Use index as x value
        .y(d => yScale(d.EMA21))
      );

    const ema120Line = g.append('path')
      .datum(data)
      .attr('class', 'ema-line')
      .attr('fill', 'none')
      .attr('stroke', 'red')  // Red for EMA120
      .attr('stroke-width', 1.5)
      .attr('d', d3.line()
        .defined(d => d.EMA120 != null)
        .x((d, i) => xScale(i)) // Use index as x value
        .y(d => yScale(d.EMA120))
      );

    const crosshairX = svg.append('line')
      .attr('class', 'crosshair')
      .attr('stroke', 'rgba(135, 138, 153, 0.8)')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '4 4')
      .style('display', 'none');

    const crosshairY = svg.append('line')
      .attr('class', 'crosshair')
      .attr('stroke', 'rgba(135, 138, 153, 0.8)')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '4 4')
      .style('display', 'none');

    const tooltip = d3.select(tooltipRef.current)
      .style('left', '60px') // Fixed position
      .style('top', '-8px') // Fixed position
      .style('font-size', '11px')
      .attr('fill', '#bac3cd');

    const xLabel = svg.append('text')
      .attr('class', 'x-label')
      .attr('text-anchor', 'middle')
      .style('display', 'none');

    const yLabel = svg.append('text')
      .attr('class', 'y-label')
      .attr('text-anchor', 'end')
      .style('display', 'none');

    let newXScale = xScale;

    const minZoomDays = 7;
    const maxZoomFactor = 40;

    const zoom = d3.zoom()
      .scaleExtent([1, maxZoomFactor])
      .translateExtent([[xScale(0), 0], [xScale(validData.length - 1), chartHeight]])
      .extent([[0, 0], [chartWidth, chartHeight]])
      .on('zoom', (event) => {
        const { transform } = event;
        newXScale = transform.rescaleX(xScale);

        const [minX, maxX] = newXScale.domain();

        if (minX < 0) {
          transform.x = xScale(0);
          newXScale.domain([0, maxX]);
        }
        if (maxX > validData.length - 1) {
          transform.x = xScale(validData.length - 1) - chartWidth;
          newXScale.domain([minX, validData.length - 1]);
        }

        candlestickWidth = getCandlestickWidth(newXScale);

        const visibleData = validData.filter((d, i) => 
          newXScale(i) >= newXScale.range()[0] && 
          newXScale(i) <= newXScale.range()[1]
        );

        newYScale = d3.scaleLinear()
          .domain([
            d3.min(visibleData, d => d.Low) - 1,
            d3.max(visibleData, d => d.High) + 1
          ])
          .range([chartHeight, 0]);

        newYVolumeScale = d3.scaleLinear()
          .domain([0, d3.max(visibleData, d => d.Volume)])
          .range([(chartHeight / 2), 0]);

        updateChart(newXScale, newYScale, newYVolumeScale);
      });

    svg.call(zoom);

    const updateChart = (newXScale, newYScale, newYVolumeScale) => {
      g.selectAll('.candlestick')
        .attr('transform', (d, i) => `translate(${newXScale(i)},0)`);

      g.selectAll('.candlestick .body')
        .attr('x', -candlestickWidth / 2 + dayPadding / 2)
        .attr('width', candlestickWidth - dayPadding)
        .attr('y', d => newYScale(Math.max(d.Open, d.Close)))
        .attr('height', d => Math.abs(newYScale(d.Open) - newYScale(d.Close)));

      g.selectAll('.candlestick .high-low')
        .attr('y1', d => newYScale(d.High))
        .attr('y2', d => newYScale(d.Low));

      // Update Bollinger Bands area
      g.select('.bollinger-area')
    .attr('d', d3.area()
        .x((d, i) => newXScale(i)) // Use index as x value
        .y0(d => newYScale(d.lower))
        .y1(d => newYScale(d.upper))
    );

    upperBandLine
    .attr('d', d3.line()
        .defined(d => d.upper != null)
        .x((d, i) => newXScale(i)) // Use index as x value
        .y(d => newYScale(d.upper))
    );

lowerBandLine
    .attr('d', d3.line()
        .defined(d => d.lower != null)
        .x((d, i) => newXScale(i)) // Use index as x value
        .y(d => newYScale(d.lower))
    );

      maLine
        .attr('d', d3.line()
          .defined(d => d.mean != null)
          .x((d, i) => newXScale(i)) // Use index as x value
          .y(d => newYScale(d.mean))
        );

      tpLine
        .attr('d', d3.line()
          .defined(d => d.TP != null)
          .x((d, i) => newXScale(i)) // Use index as x value
          .y(d => newYScale(d.TP))
        );

      ema8Line
        .attr('d', d3.line()
          .defined(d => d.EMA8 != null)
          .x((d, i) => newXScale(i)) // Use index as x value
          .y(d => newYScale(d.EMA8))
        );

      ema21Line
        .attr('d', d3.line()
          .defined(d => d.EMA21 != null)
          .x((d, i) => newXScale(i)) // Use index as x value
          .y(d => newYScale(d.EMA21))
        );

      ema120Line
        .attr('d', d3.line()
          .defined(d => d.EMA120 != null)
          .x((d, i) => newXScale(i)) // Use index as x value
          .y(d => newYScale(d.EMA120))
        );

        g.selectAll('.volume-bar')
        .attr('x', (d, i) => newXScale(i) - candlestickWidth / 2 + dayPadding / 2)
        .attr('width', candlestickWidth - dayPadding)
        .attr('y', d => (chartHeight / 2) + newYVolumeScale(d.Volume)) // Position in the bottom half
        .attr('height', d => (chartHeight / 2) - newYVolumeScale(d.Volume));

      xAxisGroup.call(xAxis.scale(newXScale).ticks(7));
      yAxisGroup.call(yAxis.scale(newYScale).ticks(7));
      yVolumeAxisGroup.call(yVolumeAxis.scale(newYVolumeScale).ticks(7));

      // Restore the consistent styles to grid lines and labels
      xAxisGroup.selectAll('path, line')
        .attr('stroke', 'rgba(135, 138, 153, 0.3)')
        .attr('stroke-dasharray', '2,2');

      yAxisGroup.selectAll('path, line')
        .attr('stroke', 'rgba(135, 138, 153, 0.3)')
        .attr('stroke-dasharray', '2,2');

      yVolumeAxisGroup.selectAll('path, line')
        .attr('stroke', 'none'); // Remove grid lines for volume axis

      // Ensure tick markers and labels are consistent
      xAxisGroup.selectAll('text')
        .style('fill', '#bac3cd')
        .attr('transform', 'translate(0, 5)')
        .style('font-weight', '400')
        .style('text-anchor', isMobile ? 'end' : 'middle') // Adjust text-anchor based on device
        .attr('dx', isMobile ? '-0.8em' : '0')
        .attr('dy', isMobile ? '0.15em' : '10')
        .attr('transform', isMobile ? 'rotate(-45)' : ''); // Rotate the tick labels only on mobile

      yAxisGroup.selectAll('text')
        .style('fill', '#54b19a')
        .attr('transform', 'translate(-5, 0)')
        .style('font-weight', '400');

      yVolumeAxisGroup.selectAll('text')
        .style('fill', '#bac3cd')
        .style('font-weight', '400')
        .attr('transform', 'translate(5, 0)')
        .style('text-anchor', 'start');

      yVolumeAxisGroup.selectAll('.tick line')
        .attr('stroke', '#54b19a')
        .attr('stroke-width', 1);
    };

    const xAxisGroup = svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(${margin.left},${dimensions.height - margin.bottom})`);

    const yAxisGroup = svg.append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${margin.left},${margin.top})`);

      const yVolumeAxisGroup = svg.append('g')
      .attr('class', 'y-volume-axis')
      .attr('transform', `translate(${dimensions.width - margin.right}, ${margin.top + chartHeight / 2})`);
    

    svg.append('rect')
      .attr('class', 'overlay')
      .attr('width', chartWidth)
      .attr('height', chartHeight)
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseover', () => {
        crosshairX.style('display', null);
        crosshairY.style('display', null);
        tooltip.style('display', 'block');
      })
      .on('mouseout', () => {
        crosshairX.style('display', 'none');
        crosshairY.style('display', 'none');
        tooltip.style('display', 'none');
      })
      .on('mousemove', function (event) {
        const [mouseX, mouseY] = d3.pointer(event, this);

        // Map mouse X position to corresponding index using the newXScale
        const xIndex = Math.round(newXScale.invert(mouseX));
        const yPrice = newYScale.invert(mouseY);
        const yVolume = Math.max(0, newYVolumeScale.invert(Math.min(mouseY, chartHeight)));

        const xDate = newXScale.invert(mouseX);

        // Get the closest data point based on the index
        const closestData = validData[xIndex];

        // Update crosshairs to match the mouse position
        crosshairX
          .attr('x1', newXScale(xIndex) + margin.left)
          .attr('x2', newXScale(xIndex) + margin.left)
          .attr('y1', margin.top)
          .attr('y2', chartHeight + margin.top);

        crosshairY
          .attr('x1', margin.left)
          .attr('x2', chartWidth + margin.left)
          .attr('y1', mouseY + margin.top)
          .attr('y2', mouseY + margin.top);

        // Update tooltip content
        if (closestData) {
          tooltip.html(`
            <div> Candlestick  OHLC: [${d3.format("$.2f")(closestData.Open)}, ${d3.format("$.2f")(closestData.High)}, ${d3.format("$.2f")(closestData.Low)}, <span style="color: #54b19a; font-weight: bold">${d3.format("$.2f")(closestData.Close)}</span>]  Target Price: ${d3.format("$.2f")(closestData.TP)} Volume: ${d3.format(",")(closestData.Volume)}M</div>
            <div>EMA (8,21,120): [${closestData.EMA8 ? d3.format("$.2f")(closestData.EMA8) : 'N/A'}, ${closestData.EMA21 ? d3.format("$.2f")(closestData.EMA21) : 'N/A'}, ${closestData.EMA120 ? d3.format("$.2f")(closestData.EMA120) : 'N/A'}]</div>
          `);

         // Remove previous label groups
svg.selectAll('.x-axis-label-group').remove();
svg.selectAll('.top-date-label-group').remove();
svg.selectAll('.y-axis-label-group').remove();
svg.selectAll('.volume-label-group').remove();

// Ensure closestData is defined and has a valid date
if (closestData && closestData.date instanceof Date && !isNaN(closestData.date)) {
  const formattedDate = d3.timeFormat('%b %d, %Y')(closestData.date);

  // X-Axis Label (Date) - Arrow pointing up
  const xAxisLabelGroup = svg.append('g')
    .attr('class', 'x-axis-label-group')
    .attr('transform', `translate(${mouseX + margin.left - 30}, ${dimensions.height - margin.bottom + 5})`); // Closer to the axis

  xAxisLabelGroup.append('rect')
    .attr('width', 70)
    .attr('height', 20)
    .attr('fill', '#878a99') // Gridline color
    .attr('rx', 3)
    .attr('ry', 3);

  xAxisLabelGroup.append('polygon')
    .attr('points', '35,-5 30,0 40,0') // Arrow pointing up
    .attr('fill', '#878a99'); // Gridline color

  xAxisLabelGroup.append('text')
    .attr('x', 5)
    .attr('y', 14)
    .attr('fill', 'white')
    .attr('font-size', '10px')
    .attr('text-anchor', 'start')
    .text(formattedDate);

  // Top X-Axis Label (Date) - Arrow pointing down
  const topDateLabelGroup = svg.append('g')
    .attr('class', 'top-date-label-group')
    .attr('transform', `translate(${mouseX + margin.left - 30}, ${margin.top - 25})`); // Closer to the axis

  topDateLabelGroup.append('rect')
    .attr('width', 70)
    .attr('height', 20)
    .attr('fill', '#878a99') // Gridline color
    .attr('rx', 3)
    .attr('ry', 3);

  topDateLabelGroup.append('polygon')
    .attr('points', '35,25 30,20 40,20') // Arrow pointing down
    .attr('fill', '#878a99'); // Gridline color

  topDateLabelGroup.append('text')
    .attr('x', 5)
    .attr('y', 14)
    .attr('fill', 'white')
    .attr('font-size', '10px')
    .attr('text-anchor', 'start')
    .text(formattedDate);
} else {
  console.error("Invalid date for closestData:", closestData.date);
}
          // Y-Axis Label (Price) - Arrow pointing right
          const yAxisLabelGroup = svg.append('g')
            .attr('class', 'y-axis-label-group')
            .attr('transform', `translate(${margin.left - 65}, ${mouseY + margin.top - 10})`); // Adjust position

          yAxisLabelGroup.append('rect')
            .attr('width', 60)
            .attr('height', 20)
            .attr('fill', '#878a99') // Gridline color
            .attr('rx', 3)
            .attr('ry', 3);

          yAxisLabelGroup.append('polygon')
            .attr('points', '67,10 57,15 57,5') // Arrow pointing right
            .attr('fill', '#878a99');

          yAxisLabelGroup.append('text')
            .attr('x', 55) // Align text to the right inside the box
            .attr('y', 14)
            .attr('fill', 'white')
            .attr('font-size', '10px')
            .attr('text-anchor', 'end') // Align text to the right
            .text(d3.format("$.2f")(yPrice));


// Calculate yVolume based on the mouse position
const yVolume = Math.max(0, newYVolumeScale.invert(Math.min(mouseY - chartHeight / 2, chartHeight / 2)));

// Volume Label (Arrow pointing left)
const volumeYPosition = margin.top + mouseY;

// Get the position of the highest tick on the volume axis
const highestTickValue = newYVolumeScale.domain()[1];
const highestTickPosition = newYVolumeScale(highestTickValue) + chartHeight / 2;

// Ensure yVolume is displayed only when it is below the highest tick value
if (yVolume <= highestTickValue && volumeYPosition >= highestTickPosition + 20) {
    const volumeLabelGroup = svg.append('g')
      .attr('class', 'volume-label-group')
      .attr('transform', `translate(${dimensions.width - margin.right + 6}, ${volumeYPosition - 10})`);

    volumeLabelGroup.append('rect')
      .attr('width', 50)
      .attr('height', 20)
      .attr('fill', '#878a99') // Gridline color
      .attr('rx', 3)
      .attr('ry', 3);

    volumeLabelGroup.append('polygon')
      .attr('points', '-5,10 5,15 5,5') // Arrow pointing left
      .attr('fill', '#878a99');

    volumeLabelGroup.append('text')
      .attr('x', 5) // Padding inside the box
      .attr('y', 14)
      .attr('fill', 'white')
      .attr('font-size', '10px')
      .attr('text-anchor', 'start')
      .text(`${Math.round(yVolume)}M`); // Ensure non-negative values
}



        }

      });

    svg.call(zoom.transform, d3.zoomIdentity);

    xAxisGroup.call(xAxis);
    yAxisGroup.call(yAxis);
    yVolumeAxisGroup.call(yVolumeAxis);

    xAxisGroup.selectAll('path, line')
      .attr('stroke', 'rgba(135, 138, 153, 0.3)')
      .attr('stroke-dasharray', '2,2');
    xAxisGroup.selectAll('text')
      .style('fill', '#bac3cd')
      .style('font-weight', '400')
      .style('font-size', '9px')
      .attr('text-anchor', 'middle');

    yAxisGroup.selectAll('path, line')
      .attr('stroke', 'rgba(135, 138, 153, 0.3)')
      .attr('stroke-dasharray', '2,2');
    yAxisGroup.selectAll('text')
      .style('fill', '#54b19a')
      .style('font-weight', '400')
      .style('font-size', '9px')
      .attr('text-anchor', 'end');

    yVolumeAxisGroup.selectAll('path, line')
      .attr('stroke', 'none');
    yVolumeAxisGroup.selectAll('text')
      .style('fill', '#bac3cd')
      .style('font-weight', '400')
      .style('font-size', '9px')
      .attr('text-anchor', 'end');

    yVolumeAxisGroup.selectAll('.tick line')
      .attr('stroke', '#bac3cd')
      .attr('stroke-width', 1);

  }, [data, dimensions]);

  return (
    <div id="chart-container" style={{ position: 'relative', width: '100%' }}>
      <div id="tooltip" ref={tooltipRef} style={{ position: 'absolute', display: 'none' }}></div>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default StockChart;
