import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
Australian Stock Report Pty Ltd. ACN 106 863 978 AFS Licence 301682 (ASR) is licensed as an Australian Financial Services Licensee pursuant to section 913B of the Corporations Act 2001 (Cth).
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
							<a href="https://report.australianstockreport.com.au/docs/ASR-Finance-Guide.pdf" target="_blank">Financial Services Guide</a>/<a href="http://www.australianstockreport.com.au/privacy-policy/" target="_blank">Privacy Policy</a>/<a href="http://www.australianstockreport.com.au/terms-conditions/" target="_blank">Terms &amp; Conditions</a>/<a href="http://www.australianstockreport.com.au/disclaimer/" target="_blank">Disclaimer</a>/<a href="javascript:void(0);" onclick="openURL('Contact an Adviser', '../msr/report/inc_open_trading_account.cfm', 'NA', 'ASR');">Contact an Adviser</a>
					
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;