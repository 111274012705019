import React, { memo,useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumbSVG from '../../../Components/Common/BreadCrumbSVG';
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../config";
import classnames from "classnames";
//import StockData from '../../../Components/Common/StockData';

import ASRTracker from '../ASRTracker';




//import images
import reportLogo from "../../../assets/images/products/MSR_ETF.svg";


const ETF = () => {
  document.title="Australian Stock Report | ETF Report";

    const [activeTab, setActiveTab] = useState("1");
  
    const toggleTab = (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    };

  return (
    <React.Fragment>      
      <div className="page-content">
        <Container fluid>
        <BreadCrumbSVG  title="ETF Report" pageTitle="Reports"
                svgComponent={<img src={reportLogo} alt="ETF Report Icon" style={{ height: 30, width: 30, margin: '0px', display: 'inline-block' }} />}
            />
          <Row>
          <Col xl={2}>
            <Card>
             <CardBody className="p-0"> Latest Weekly </CardBody>
            </Card> 
            <Card>
             <CardBody className="p-0"> Investing Guidelines</CardBody>
            </Card> 
            </Col>
            <Col xl={2}>
            <Card>
             <CardBody className="p-0">  Sector breakdown  </CardBody>
            </Card>   
            </Col>
            <Col xl={2}>
            <Card>
             <CardBody className="p-0">    Return   </CardBody>
            </Card> 
            </Col>
            <Col xxl={6}>
             
            </Col>
            
          </Row>  

          <Row>
      <Col>
              Trade Ideas
              <ASRTracker streamCode="ETF" uFolder="pats" />
      </Col>
    </Row>


      
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ETF;