import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import TableContainer from "./TableReportContainer";
import { Spinner } from 'reactstrap';
import { api } from "../../config";

const fetchMain = async ({ queryKey }) => {
  const [, stream, position] = queryKey; // Include position in queryKey
  const clientID = sessionStorage.getItem('clientID');
  const sessionloginKey = sessionStorage.getItem('loginKey');

  try {
    const response = await axios.get(api.API_URL + `/inc_report_data.cfm?action=qASR&s=${stream}&position=${position}&clientID=${clientID}`, {
      headers: {
        'X-User-GUID': sessionloginKey,
        'Authorization': sessionloginKey
      }
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized: Invalid login key');
      window.location.href = '/login'; // Redirect to login page
    } else {
      console.error('An error occurred:', error.message);
      throw new Error('Data Error'); // Throw generic error for UI handling
    }
  }
};


const TableReportASR = ({ stream,position, columns, ExpandRows }) => { // Accept ExpandRows as a prop
  const { data: searchTable, isLoading, isError } = useQuery({
    queryKey: ['mainQuery', stream, position], // Include position in queryKey
    queryFn: fetchMain,
  });

  const memoizedColumns = useMemo(() => columns, [columns]);

  if (isLoading) {
    return <Spinner>Loading...</Spinner>;
  }

  if (isError) {
    return <div>Error loading data...</div>;
  }

  return (
    <React.Fragment>
      {/* Pass ExpandRows to TableContainer */}
      <TableContainer
        columns={memoizedColumns || []}
        data={searchTable || []}
        isGlobalFilter={true}
        customPageSize={15}
        divClass="table-responsive table-card mb-0"
        tableClass="table-centered align-middle table-wrap mb-0"
        theadClass="text-muted table-light"
        SearchPlaceholder='Search...'
        ExpandRows={ExpandRows}  // Pass ExpandRows here
      />
    </React.Fragment>
  );
};

export default TableReportASR;
